//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import '~/assets/st/st.css'
import axios from "axios";
// import Header from '@/components/Header.vue'
// import Footer from '@/components/Footer.vue'
import TopMarquee from '@/components/TopMarquee.vue'
const Header = ()=>import('@/components/Header.vue');
const Footer = ()=>import('@/components/Footer.vue');


export default {
  components: {
    Header,
    Footer,
    TopMarquee,
  },
  // head : {



  //   },
  head() {
    return {
      bodyAttrs: {
          class: 'w-store'
        },
  
      link:[
         {
              rel: 'canonical',
              href: this.domain + this.$route.path
            },
      ],
      
      script: [
           
            // { src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js', defer: true },
            // { src: 'https://www.googletagmanager.com/gtag/js?id=AW-870183712', defer: true },
            // {rel:'preconnect', src: "~/static/css/bootstrap.min.css"},
            // {rel:'preconnect', src: "~/static/css/header-footer.css"},
            // {rel:'preconnect', src: "~/static/css/style.css"},
            // {rel:'preconnect', src: "~/static/css/responsive.css"},
            // {rel:'preconnect', src: "~/static/css/webvital.css"},
        ]
    }
  },
  name: "pimweb",
  computed: {


  },
  watch: {

    '$store.state.list.search_input': {
      handler: function (after, before) {
        if (after == '') {
          if (this.$store.state.list.path != '') {
            this.$router.push(this.$store.state.list.path);
          }
          if (this.$store.state.list.path == '/st-search') {
            this.$router.push('/');
          }
        }

        if (before == '') {
          this.$store.state.list.path = this.$route.path;
          this.$router.push(`/st-search?q=${this.$store.state.list.search_input}`);
        }
        else {
          if (after != '') {
            this.$router.push({ query: { ...this.$route.query, q: this.$store.state.list.search_input } });
          }
        }
      }
    },
    '$store.state.list.cart_product': {
      handler: function (after, before) {
        this.summary()
        this.gtm_product = [];
        for (let i = 0; i < this.$store.state.list.cart_product.length; i++) {
          let name = this.$store.state.list.cart_product[i].name;
          let id = this.$store.state.list.cart_product[i].master_sku;
          let price = this.$store.state.list.cart_product[i].selling_price;
          let variant = this.$store.state.list.cart_product[i].size;
          let category = this.$store.state.list.cart_product[i].category;
          let quantity = this.$store.state.list.cart_product[i].qty;
          this.gtm_product.push({ name, id, price, variant, category, quantity });
        }
        this.$store.state.list.gtm_product = this.gtm_product
      }
    },
    '$store.state.list.customer_id': {
      handler: function (after, before) {
        if (this.$store.state.list.customer_id != null && this.$store.state.list.customer_id != '') {
          this.$store.state.list.login_status = 'Registered';
        }
        else {
          this.$store.state.list.login_status = 'Guest';
        }
      }
    },
    $route(to, from) {
      if(to.name !== "category") {
        this.$store.state.list.plpSeoContent = ''
      }
      //  if (
      //   to.name === "cart" ||
      //   to.name === "checkout" ||
      //   to.name === "Cart" ||
      //   to.name === "Checkout"
      // ) {
      //   this.isShowFooter = false;
      // } else {
      //   this.isShowFooter = true;
      // }
      if (this.$route.name == 'st-search-slug') {
        document.querySelector("input[name='st']").value = this.$route.query.q;
        this.$store.state.list.search_input = this.$route.query.q;
      }
      else {
        document.querySelector("input[name='st']").value = '';
        this.$store.state.list.search_input = '';
        this.$store.state.list.path = '';
      }
      this.$store.state.list.menuActive = true;
      if (this.$route.name != 'category') {
        this.$store.state.list.breadcrumb = '';
        this.$store.state.list.breadcrumb_second.name = '';
        this.$store.state.list.breadcrumb_second.url = '';
      }
      if ((this.$store.state.list.thankyou_status === false || this.$store.state.list.thankyou_status === null) && this.$route.name === 'thankyou') {
        this.$router.push('/')
      }
      if (this.login_status === 'Registered') {
        this.$gtm.push({
          event: 'Login_status',
          category: 'Login_status',
          action: 'Logged in',
          Mobile_no: this.$store.state.list.customer_detail.phone,
          Email: this.$store.state.list.customer_detail.email,
        });

      }
      else {
        this.$gtm.push({
          event: 'Login_status',
          category: 'Login_status',
          action: 'Not Logged in',
          Mobile_no: '',
          Email: '',
        });
      }
    },
  },
  data: function () {
    return {
      showCookieDisclaimer: false,
      isShowFooter: true,
      menu: [],
      favicon: "",
      domain: "https://walkwayshoes.com",
      marqueeText: '',
    };
  },
  mounted() {
    console.log('process?.env?.META',process?.env?.META)
    window.addEventListener('scroll', this.showHideTopScroll);
    document.getElementById('scroll-to-top').style.display = 'none'
    document.getElementById('scroll-to-top').addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    })
    setTimeout(() => {
      this.showCookieDisclaimer = true;
      if ($cookies.get("acceptCookies")) {
        this.showCookieDisclaimer = false;
      }
    }, 3000);

    // document.addEventListener("mouseup", (e) => {
    //   this.$toast.clear();
    // });
  },
  beforeMount: function () {
    var currentUrl = location.host;
    this.$store.state.list.thankyou_status = $cookies.get(window.location.hostname.substring(10, 4) + '_thank');
    this.$store.state.list.cart_id = $cookies.get(window.location.hostname.substring(10, 4) + '_cart');
    this.$store.state.list.cart_session = $cookies.get(window.location.hostname.substring(10, 4) + '_ct_sess');
    this.$store.state.list.customer_session = $cookies.get(window.location.hostname.substring(10, 4) + '_cus_sess');
    this.$store.state.list.thankyou_cart = $cookies.get(window.location.hostname.substring(10, 4) + '_cart');
    this.$store.state.list.customer_id = $cookies.get(window.location.hostname.substring(10, 4) + '_customer');
    if ((this.$store.state.list.thankyou_status === false || this.$store.state.list.thankyou_status === null) && this.$route.name === 'thankyou') {
      this.$router.push('/')
    }

    if ($cookies.get("optimizeLink")) {
      this.$store.state.list.optimiseLink = true;
    }
    let query = this.$route.query;
    if (query.utm_source && query.utm_medium && query.utm_campaign) {
      $cookies.set("optimizeLink", true, "30d");

      this.$store.state.list.optimiseLink = true;
    }

    if (
      this.$route.name === "cart" ||
      this.$route.name === "checkout"
    ) {
      this.isShowFooter = false;
    } else {
      this.isShowFooter = true;
    }
    this.get_account();
    if (this.$store.state.list.customer_id != "" && this.$store.state.list.customer_id != null) {
      this.get_wish();
    }
    // this.get_page();
    // this.get_charges();
    if (
      this.$route.name != "cart" &&
      this.$route.name != "checkout" &&
      this.$route.name != "thankyou"
    ) {
      this.get_cart();
    }
    if (this.$store.state.list.store === 1) {
      this.favicon = "/images/fav.png";
    } else {
      this.favicon = "/images/fav2.ico";
    }
  },
  async fetch() {
    await this.get_menu()
    await this.getMarquee()
  },
  methods: {
    showHideTopScroll() {
      if (window.pageYOffset > 150) {
        document.getElementById('scroll-to-top').style.display = 'block'

      } else {
        document.getElementById('scroll-to-top').style.display = 'none'
      }
    },
    afterLeave() {
      // this.$store.state.list.$emit("triggerScroll");
    },
    summary() {
      let totalMRP = 0, discountMRP = 0
      this.$store.state.list.cart_product.forEach(element => {
        totalMRP = totalMRP + element.price * element.qty
        discountMRP = discountMRP + element.selling_price * element.qty


      });
      discountMRP = totalMRP - discountMRP

      this.$store.state.list.summary = { totalMRP: totalMRP, discountMRP: discountMRP }

    },
    googleAnalyticsTag(event, coupon = '') {
      let items = this.cart_product.map(x => {
        let item = {
          id: x.master_sku,
          name: x.name,
          quantity: x.qty,
          price: x.selling_price,
          variant: x.colour,
          brand: x.brand
        }
        return item
      })
      if (coupon) {
        gtag('event', event, {
          items: items,
          coupon: coupon
        });

      } else {
        gtag('event', event, {
          items: items
        });
      }

    },
    get_wish() {
      var form = new FormData();
      form.append("customer_id", this.$store.state.list.customer_id);
      form.append("customer_session", this.$store.state.list.customer_session);
      axios
        .post(this.$store.state.list.cart_api_url + `/wishlist/customer-wishlist`, form)
        .then((response) => {
          if (response.data.success == true) {
            //this.$store.state.list.wishpro=response.data.data.product.split(',');
            this.$store.state.list.wishlist=response.data.data.product?.split(',');
            this.$store.state.list.wishlist_count = response.data.data.total_row;
          }
        });
    },
    get_charges: function () {
      axios
        .get(this.$store.state.list.cart_api_url + "/extracharges/extra-charge")
        .then((response) => {
          if (response.data.success === true) {
            this.$store.state.list.shipping_charges =
              response.data.data.amountChargedShipping;
            this.$store.state.list.shipping_charges_below =
              response.data.data.amountChargedBelowShipping;
          }
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            this.$store.state.list.error_message =
              "Oops there seems to be some Network issue, please try again.";
          }
          window.scrollTo(0, 0);
        });
    },
    get_account: function () {
      if (this.$store.state.list.customer_id != null && this.$store.state.list.customer_id != "") {
        this.$store.state.list.error_message = "";
        this.$store.state.list.success_message = "";
        var form = new FormData();
        form.append("customer_id", this.$store.state.list.customer_id);
        form.append("customer_session", this.$store.state.list.customer_session);
        form.append("store", this.$store.state.list.store);
        axios
          .post(this.$store.state.list.cart_api_url + "/customer/account-details", form)
          .then((response) => {
            if (response.data.success === true) {
              this.$store.state.list.page_loader = false;
            } else {
              if (
                response.data.cart_session_status == false ||
                response.data.customer_session_status == false
              ) {
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_customer",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cus_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.customer_id = "";
                this.$store.state.list.customer_session = "";
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_ct_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.cart_id = "";
                this.$store.state.list.cart_session = "";
                this.$store.state.list.cart_product = [];
              }
            }
          });
      }
    },
    get_menu: async function () {
      await axios
        .get(
          this.$store.state.list.api_url +
          `/pim/pimresponse.php/?service=menu&store=${this.$store.state.list.store}`
        )
        .then((response) => {
          response.data.result.forEach;
          this.$store.state.list.menu = response.data.result;
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            this.$store.state.list.error_message =
              "Oops there seems to be some Network issue, please try again.";
          }
          window.scrollTo(0, 0);
        });
    },
    get_cart: function() {
      if (
        this.$store.state.list.cart_id != null &&
        this.$store.state.list.cart_id != "" &&
        this.$route.name != "thankyou" &&
        this.$route.name != "validate_points"
      ) {
        var form = new FormData();
        form.append("cart_id", this.$store.state.list.cart_id);
        form.append("cart_session", this.$store.state.list.cart_session);
        axios
          .post(this.$store.state.list.cart_api_url + "/cart/get-cart", form)
          .then((response) => {
            if (response.data.success === true) {
              this.$store.state.list.cart_product = response.data.data.products;
              this.$store.state.list.customer_detail = response.data.data.customer;
              this.$store.state.list.cart_total = response.data.data.grand_total;
              this.$store.state.list.cart_subtotal = response.data.data.order_subtotal;
              this.$store.state.list.address = response.data.data.address;
              this.$store.state.list.discount_code = response.data.data.discount_code;
              this.$store.state.list.discount_amount = response.data.data.discount_amount;
              if (response.data.data.cookieDelete === 1) {
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  ""
                );
                this.$store.state.list.cart_id = "";
              }
              if (this.$store.state.list.address.length != 0) {
                for (let i = 0; i < this.$store.state.list.address.length; i++) {
                  if (i == 0) {
                    this.$store.state.list.address_id = this.$store.state.list.address[i].id * 1;
                    this.$store.state.list.select_address_no = i;
                  }
                }
              }
            } else {
              this.$store.state.list.error_message = response.data.message;
              if (response.data.cart_session_status == false) {
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_customer",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cus_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.customer_id = "";
                this.$store.state.list.customer_session = "";
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_cart",
                  "",
                  "1y"
                );
                $cookies.set(
                  window.location.hostname.substring(10, 4) + "_ct_sess",
                  "",
                  "1y"
                );
                this.$store.state.list.cart_id = "";
                this.$store.state.list.cart_session = "";
                this.$store.state.list.cart_product = [];
              }
            }
          })
          .catch((error) => {
            if (error.message === "Network Error") {
              this.$store.state.list.error_message =
                "Oops there seems to be some Network issue, please try again.";
            }
            window.scrollTo(0, 0);
          });
      }
    },
    async getMarquee(){
      try {
        const response  = await axios('https://pim.walkwayshoes.com/pim/pimresponse.php/?service=ticker')
        if (response.data.result[0].content) {
          this.marqueeText = response.data.result[0].content
        }else {
          this.marqueeText = ''
        }
      } catch (error) {
      }
    },
  },
};
